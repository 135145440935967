<template>
  <div style="display: contents" :key="tournament.jsonProps && tournament.jsonProps.length">
    <v-btn color="color3" text x-small @click.stop="open">Add-on Products</v-btn>
    <v-navigation-drawer
      right
      v-model="isOpen"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
      :permanent="editing"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
        <v-toolbar color="color2 color2Text--text" tile dense>
          <v-toolbar-title>Add-on Products</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            fab
            small
            @click.stop="cancel(true)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <div>
          <v-fab-transition>
            <v-btn color="error" text small @click.stop="onDeleteClick" v-if="editing">
              remove this add-on
            </v-btn>
          </v-fab-transition>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="activeBtn.color"
              :disabled="activeBtn.disabled"
              :loading="activeBtn.loading"
              @click.stop="activeBtn.onClick"
              block
              class="py-9"
            >
              {{ activeBtn.text }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error white--text"
              @click.stop="activeBtn.cancel"
              :disabled="activeBtn.loading"
              block
              class="py-9"
            >
            {{activeBtn.cancelText || 'Close'}}
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-card-text>
        <v-container class="pt-10 px-0">
          <v-row dense v-if="!myAddOns">
            <v-col cols="12" v-if="currentAddOns">
              <v-alert type="error" prominent text>
                To complete the deletion of this add-on click save.
              </v-alert>
            </v-col>
            <v-col cols="12" class="text-center" v-else>
              <div class="text-h6">You do not currently have any add-ons assigned to this registration</div>
              <v-alert type="error" prominent text v-if="productsLoaded && products.length === 0">
                You do not have any products available. You must create the product before you can add it to a registration as an add-on.
              </v-alert>
              <v-btn color="color3" text @click.stop="addAddOns" v-else>Add a product</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-text-field
                label="Suggestion Text"
                v-model="myAddOns.question"
                :disabled="!editing || loading"
                color="color3"
                item-color="color3"
              ></v-text-field>
              <v-list>
                <v-slide-x-transition  group mode="out-in" leave-absolute>
                  <v-col cols="12" v-for="i in suggestionCount" :key="i">
                    <v-select
                      :items="products"
                      item-text="title"
                      item-value="urlTag"
                      :label="`Suggestion ${i}`"
                      v-model="myAddOns.productTags[i - 1]"
                      :disabled="!editing || loading"
                      clearable
                      color="color3"
                      item-color="color3"
                    ></v-select>
                  </v-col>
                </v-slide-x-transition>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
        <v-overlay color="white" v-if="loading">
            <div class="text-center">
              <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
            </div>
            <div class="color3--text text-center text-h4">Loading</div>
          </v-overlay>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>

export default {
  props: ['tournament'],
  data () {
    return {
      isOpen: false,
      editing: false,
      iAddOns: null,
      loading: false,
      products: [],
      productsLoaded: false,
      window: 0
    }
  },
  computed: {
    activeBtn () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        text: 'Edit',
        cancel: () => {
          this.cancel(true)
        },
        loading: this.loading,
        disabled: (this.productsLoaded && this.products.length === 0) || !this.currentAddOns
      } : {
        color: 'success white--text',
        icon: 'fas fa-times',
        onClick: this.onSaveClick,
        text: 'save',
        cancelText: 'Cancel',
        cancel: this.cancel,
        disabled: !this.dirty || (this.dto && this.dto.productTags.length === 0),
        loading: this.loading
      }
    },
    currentAddOns () {
      return this.tournament && this.tournament.jsonProps && this.tournament.jProps && this.tournament.addOns
    },
    myAddOns () {
      return this.editing ? this.iAddOns : this.currentAddOns
    },
    suggestionCount () {
      const n = this.myAddOns.productTags.filter(f => !!f).length + 1

      return n >= this.products.length ? this.products.length : n
    },
    dirty () {
      return JSON.stringify(this.currentAddOns) !== JSON.stringify(this.dto)
    },
    username () {
      return this.tournament.organization.username
    },
    dto () {
      return this.iAddOns && {
        question: this.iAddOns.question,
        productTags: this.iAddOns.productTags.filter(f => !!f)
      }
    }
  },
  methods: {
    onSaveClick () {
      this.loading = true
      this.tournament.jProps.addOns = this.dto
      console.log(this.tournament.jProps)
      this.tournament.saveJProps()
        .then(r => {
          this.$emit('save-complete')
          this.cancel()
        })
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.loading = false })
    },
    edit () {
      this.iAddOns = JSON.parse(JSON.stringify(this.currentAddOns))
      this.editing = true
    },
    cancel (close) {
      this.editing = false
      this.iAddOns = null
      if (close === true) this.isOpen = false
    },
    open () {
      this.isOpen = true
    },
    getProducts () {
      this.loading = true
      this.productsLoaded = false
      this.$VBL.store.products.getList(this.username)
        .then(r => {
          this.products = r.data
          this.productsLoaded = true
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    addAddOns () {
      this.iAddOns = {
        question: 'Pre-order your tournament schwag?',
        productTags: []
      }
      this.editing = true
    },
    onDeleteClick () {
      this.iAddOns = null
    }
  },
  watch: {
    isOpen: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.getProducts()
      }
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    }
  },
  components: {
  }
}
</script>
